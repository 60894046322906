<template>
  <div class="with-nav-bar">
    <van-nav-bar :title="$t('privacy_policy')" :left-text="$t('back')" :left-arrow="true" :fixed="true" @click-left="$router.back()" />
    <van-loading v-if="state.loading" class="mt-3 text-center" vertical>{{ $t("loading_text") }}</van-loading>
    <van-empty v-else-if="state.error" image="error" :description="state.error_text" />
    <div v-else v-html="state.privacy" class="p-3"></div>
  </div>
</template>

<script>
import { Empty, Toast, Loading } from "vant";
import { getPrivacy } from "@/api/option.service";
import { hideWechatOptionMenu } from "@/utils/util";
import { reactive } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { useI18n } from "vue-i18n";
export default {
  components: {
    [Empty.name]: Empty,
    [Loading.name]: Loading,
  },
  setup() {
    const { t } = useI18n({ useScope: "global" });
    const state = reactive({
      loading: true,
      error: false,
      error_text: "",
      privacy: "<h1>" + t('privacy_policy') + "</h1>",
    });
    const loadPrivacy = async () => {
      try {
        const { result } = await getPrivacy();
        state.privacy = result.privacy;
        state.loading = false;
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : t("front_end_error"), position: "bottom" });
        if (!msg) console.log(error);
        state.loading = false;
        state.error = true;
        state.error_text = msg;
      }
    };
    onMounted(() => {
      loadPrivacy();
      hideWechatOptionMenu();
    });
    return { state };
  },
};
</script>
